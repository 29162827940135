import React, { useRef } from 'react';
import * as PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { hideSideBar, setActiveTab } from '../../actions/navBar';
import ExpansionButton from './ExpansionButton';
import ClassNames from 'classnames';
import logo from '../../assets/logos/nav_logo.svg';
import './index.css';
import Tabs from './Tabs';
import Resize from './Resize';
import SocialLinks from './SocialLinks';
import Icon from '../../components/Icon';

const NavBar = (props) => {
    const myRef = useRef(null);

    React.useEffect(() => {
        handleScroll();

        window.addEventListener('scroll', handleScroll);
        return () => window.removeEventListener('scroll', handleScroll);
    });

    const handleHome = () => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth',
        });

        if (props.activeTab) {
            props.setActiveTab(false);
        }
    };

    const handleScroll = () => {
        if (document.documentElement.scrollTop > 177) {
            myRef.current.className = 'nav_bar padding hide';
        } else {
            myRef.current.className = 'nav_bar padding';
        }
    };

    return (
        <div className="nav_bar_parent">
            <div ref={myRef} className="nav_bar padding">
                <img
                    alt="OF logo"
                    className="logo"
                    src={logo}
                    onClick={handleHome}/>
                <Resize/>
                <ExpansionButton/>
                <div className={ClassNames('nav_content', props.show ? 'show' : '')}>
                    <div className="back_button" onClick={props.handleClose}>
                        <Icon className="cross" icon="cross"/>
                    </div>
                    <Tabs handleClose={props.handleClose}/>
                    <SocialLinks handleClose={props.handleClose} lang={props.lang}/>
                </div>
            </div>
        </div>
    );
};

NavBar.propTypes = {
    handleClose: PropTypes.func.isRequired,
    lang: PropTypes.string.isRequired,
    setActiveTab: PropTypes.func.isRequired,
    show: PropTypes.bool.isRequired,
    activeTab: PropTypes.any,
};

const stateToProps = (state) => {
    return {
        lang: state.language,
        show: state.navBar.show,
        activeTab: state.navBar.activeTab,
    };
};

const actionToProps = {
    handleClose: hideSideBar,
    setActiveTab,
};

export default connect(stateToProps, actionToProps)(NavBar);
