import { INFO_MESSAGE_SHOW, SNACKBAR_HIDE } from '../constants/snackbar';
import {
    SUBMIT_CONTACT_DETAILS_ERROR,
    SUBMIT_CONTACT_DETAILS_SUCCESS,
} from '../constants/contact';

const snackbar = (state = {
    open: false,
    message: '',
}, action) => {
    switch (action.type) {
    case INFO_MESSAGE_SHOW:
    case SUBMIT_CONTACT_DETAILS_SUCCESS:
    case SUBMIT_CONTACT_DETAILS_ERROR:
        return {
            open: true,
            message: action.message,
        };
    case SNACKBAR_HIDE:
        return {
            open: false,
            message: '',
        };
    default:
        return state;
    }
};

export default snackbar;
