const variables = {
    en: {
        ecosystem: 'Ecosystem',
        why: 'Why?',
        network: 'Network',
        infrastructure: 'Infrastructure',
        dApps: 'dApps',
        roadmap: 'Roadmap',
        contact: 'Contact',
        blog: 'Blog',
        economy: 'Economy',
        markets_marketplaces: 'Markets & Marketplaces',
        section1_text1: 'Empowering creators and communities to seamlessly mint, manage, distribute & monetize their media',
        for: 'for',
        // section1_text2: 'Creators & Sovereign Communities',
        section1_text3: 'Powered by Decentralized Media & NFTs',
        explore_studio: 'Explore Studio',
        explore_insync: 'Dive into OmniFlix inSync',
        explore_streampay: 'Explore StreamPay',
        metaverse: 'for the metaverse',
        ecosystem_text1: 'Omniscient inter-chain assets and communities with token-gated pools, fractional ownership, royalties and more!',
        ecosystem_text2: 'Protocol for creating, distributing and monetizing interchain web3 assets for creators and sovereign communities (and DAOs)',
        ecosystem_text3: 'on-chain markets and off-chain web3 marketplaces for interchain NFT distribution & monetization (sovereign & white-labeled)',
        build_with: 'Built with',
        back: 'Back',
        tag_omniflix: 'Follow us 😊',
        why_omniflix: 'Why OmniFlix?',
        why_omniflix_heading1: 'Omniscient Interchain Assets',
        why_omniflix_text1: 'Mint, manage & distribute media assets, across multiple chains powered by NFTs, with extensible rights for fractionalization of ownership and increased distribution',
        why_omniflix_heading2: 'Sovereign Communities',
        why_omniflix_text2: 'Run fully decentralized public & private sovereign communities backed by on-chain governance, incentives, asset pools, memberships, social tokens (community/creator/fan tokens) and more.',
        why_omniflix_heading3: 'Collaborative Content Pools',
        why_omniflix_text3: 'Launch and participate in decentralized inter-chain collaborative media asset pools backed by NFTs and power content within dApps/contracts for transparent collaboration, effective distribution and higher monetization.',
        why_omniflix_heading4: 'Interchain Markets',
        why_omniflix_text4: 'IBC (Inter Blockchain Communication) based marketplace protocols to help creators and communities participate across multiple blockchain networks/chains to exchange (and/or) distribute assets.',
        inter_chain_assets: 'Interchain Assets',
        inter_chain_assets_info: 'crypto-native media assets powered by NFTs',
        inter_chain_assets_heading1: 'Multi-chain Distribution',
        inter_chain_assets_heading2: 'Fractional Ownership',
        inter_chain_assets_heading3: 'Proportionate Revenue Attribution',
        inter_chain_assets_text1: 'Mint, Manage & Distribute web3 media assets across multiple chains like Cosmos, Ethereum & more!',
        inter_chain_assets_text2: 'Distribute and license web3 media assets using on-chain rights management and fractionalization backed by NFTs',
        inter_chain_assets_text3: 'Earn royalties and revenue, backed by secure and transparent contracts, while distributing assets across multiple networks',
        community_middleware: 'Community Middleware',
        community_middleware_info: 'for community builders, by community builders',
        community_middleware_heading1: 'Incentivization',
        community_middleware_heading2: 'Collaborative',
        community_middleware_heading3: 'Secure',
        community_middleware_text1: 'of on-chain interactions',
        community_middleware_text2: 'content creation, curation & distribution',
        community_middleware_text3: 'access using NFTs and Token gating',
        media_asset_pools: 'Content Pools (CP)',
        inter_chain_markets: 'Interchain Markets & Marketplaces',
        inter_chain_markets_info: 'distribute & exchange assets across the metaverse',
        network_overview: 'Network Overview',
        network_overview_info: 'layers & stakeholders of the OmniFlix Network',
        network_overview_heading1: 'Creators & Communities',
        network_overview_heading2: 'Assets & Distribution',
        network_overview_heading3: 'OmniFlix Hub',
        network_overview_heading4: 'Service providers',
        network_overview_heading5: 'p2p Infrastructure',
        network_overview_text1: 'Agencies, Studios, Products & other networks',
        network_overview_text2: 'Media NFTs, Creator tokens, Token-gating,  Memberships, Content Pools, Collaborations, etc.',
        network_overview_text3: 'Validators securing the main/root chain',
        network_overview_text4: 'Relayers, APIs & App services',
        network_overview_text5: 'Storage, Compute, CDN & DNS',
        infrastructure_info: 'peer-to-peer infra for metaverse media & interactions',
        infrastructure_card1_heading: 'Storage',
        infrastructure_card1_text1: 'Vaults',
        infrastructure_card1_text2: 'Proxy',
        infrastructure_card1_text3: 'Origin + CDN',
        infrastructure_card2_heading: 'Compute',
        infrastructure_card2_text1: 'Live Encoders',
        infrastructure_card2_text2: 'Video Transcoders',
        infrastructure_card2_text3: 'AI/ML Models',
        infrastructure_card3_heading: 'Network',
        infrastructure_card3_text1: 'DNS',
        infrastructure_card3_text2: 'CDN',
        infrastructure_card3_text3: 'Resolvers',
        infrastructure_card4_heading: 'Services',
        infrastructure_card4_text1: 'App Engines',
        infrastructure_card4_text2: 'API Providers',
        infrastructure_card4_text3: 'Relayers',
        infrastructure_link_info: 'Participiate in a decentralized economy by running infrastructure for OmniFlix network.',
        infrastructure_button: 'Run p2p Infra',
        // dApps_ecosystem: 'Explore our dApps',
        dApps_ecosystem: 'Explore the suite of media dApps',
        dApps_ecosystem_info: 'cross-chain apps backed by contracts with privacy and security',
        omniflix_studio: 'OmniFlix Studio ',
        omniflix_marketplace: 'OmniFlix Marketplace',
        omniflix_nucleus: 'OmniFlix Nucleus',
        omniflix_tv: 'OmniFlix.tv',
        omniflix_me: 'OmniFlix.me',
        omniflix_insync: 'OmniFlix inSync',
        omniflix_streampay: 'StreamPay',
        try_interactive_videos: 'Try Interactive Videos',
        cross_chain_assets: 'Cross-chain Assets & dApps',
        dApps_ecosystem_test1: 'Mint your media as NFTs with just a few clicks and manage all your assets from one easy dashboard.',
        dApps_ecosystem_test2: 'Elevate your NFTs to the forefront with the first Interchain Marketplace. List, auction, and bid on assets, while gamifying your NFT experience.',
        dApps_ecosystem_sub_test2: 'open-source & white-labeled for Enterprises, Studios, DAOs, communities & more!',
        dApps_ecosystem_test3: 'Transform your video content into an interactive experience, engage your community, and receive commission-free tips directly from your audience.',
        dApps_ecosystem_sub_test3: 'Open-source & White-labeled for enterprises, Media Studios, Record Labels along with DAOs  & communities.',
        dApps_ecosystem_test4: 'Showcase your NFTs & collectibles across chains in a single profile. (Coming soon)',
        dApps_ecosystem_test5: 'Create and deploy Assets & dApps/contracts across multiple chains like Ethereum, Cosmos etc, powered by IBC (Inter Blockchain Communication)',
        dApps_ecosystem_insync: 'Leverage our open-source staking dashboard module for the Cosmos ecosystem, supporting staking & governance across 14+ Cosmos chains.',
        dApps_ecosystem_streampay: 'Streamline your token transfers with time-based transactions, choosing from 8+ Cosmos denominations on OmniFlix accounts.',
        visit_omniflix_market: 'Discover Marketplace',
        run_a_channel: 'Try OmniFlix TV ',
        create_your_inter_chain_profile: 'Create your interchain profile',
        deploy_contracts_on_testnet: 'Deploy contracts!',
        roadmap_info: 'journey so far... & road ahead...',
        roadmap_heading1: 'Started working with blockchain protocols',
        roadmap_heading2: 'Enterprise implementations',
        roadmap_text2: 'for media/partner/agreements/rights management, distribution & monetization',
        roadmap_heading3: 'Designed the base topology of the network',
        roadmap_heading4: 'Won the Best Custom Zone award in Cosmos Game of Zones',
        roadmap_text4: 'along with grants from various networks *Launched Cosmic Compass Console (alpha) (an infura for Cosmos-based sovereign chains)',
        // roadmap_heading5: 'First Testnet',
        roadmap_heading5: 'Launch of 3 testnets, protocol updates, media-tech and community centric SDKs released',
        // roadmap_heading6: 'Incentivized Testnets',
        roadmap_heading6: 'OmniFlix Chain goes live with launch of dApps',
        // roadmap_text6: 'in prep. for mainnet',
        roadmap_text6: 'OmniFlix Studio & OmniFlix Market and integration of new networks on OmniFlix inSync & publishing of interactive content on OmniFlix TV (Alpha)',
        // roadmap_heading7: 'Launch of multiple testnets, protocol updates, blockchain, media-tech and community centric SDKs',
        roadmap_heading8: 'p2p Media Network node hosting',
        roadmap_text8: '(storage, Compute, CDN, etc.)',
        roadmap_2022_ul_text1: 'OmniFlix Hub blockchain produces the genesis block on 22-2-22',
        roadmap_2022_ul_text2: 'dApps like OmniFlix Studio & OmniFlix Market get launched',
        roadmap_2022_ul_text3: 'Integration of new networks on OmniFlix inSync',
        roadmap_2022_ul_text4: 'Publishing of interactive content on OmniFlix TV (alpha) takes off',
        roadmap_ul_text1: 'Took part in Game of NFTs to build on Interchain NFTs tech stack',
        roadmap_ul_text2: '$FLIX token went live with a community led token launch using StreamSwap',
        roadmap_ul_text3: 'FlixDrop for our community, creators and contributors happened using Interact-to-Claim & StreamPay module',
        roadmap_ul_text4: 'OmniFlix TV v1 Launches bringing interactive video to Cosmos',
        roadmap_ul_text5: 'OmniFlix becomes the go-to Ticketing Partner for Cosmos Events: Cosmoverse’23, Osmocon’23 AwesomWasm',
        roadmap_ul_text6: 'Debuted AdX - our ad-space platform at Cosmoverse’23',
        roadmap_ul_text7: 'Partnership announcement with Disney Hotstar India',
        roadmap_2024_q1: 'Launch OmniFlix Hub (v3) with CosmWasm & enhanced NFT and media management features.',
        roadmap_2024_q2: 'Introduce new contracts for video/audio content creators and Media DAOs.',
        roadmap_2024_q3: 'Launch OmniFlix Media Node for decentralized media-specific infrastructure.',
        roadmap_2024_q4: ' Consolidate efforts and potentially launch IP Licensing Contracts with interchain NFT support.',
        launch_main_net: 'Launch of Mainnet',
        achievements: 'Achievements',
        best_custom_zone: 'Best Custom Zone',
        achievements_text1: 'Participant in building Interchain NFTs on Cosmos',
        achievements_text2: '3-time Media Tech partner for Cosmoverse',
        achievements_text3: 'Received multiple grants and rewards from the Akash Network',
        achievements_text4: 'Game of NFTs - Organized and participated in building Interchain NFTs on Cosmos',
        partners: 'Partners',
        mango_mass_media: 'Mango Mass’ Media',
        aditya_music: 'Aditya Music',
        exnetwork: 'Exnetwork ‘Capital',
        network_stats: 'Network Stats',
        audience_served: 'audience engaged',
        minutes_consumed: 'minutes broadcasted',
        minutes_consumed_sub_text: '140k+ interactions on videos`',
        assets_managed: 'assets managed',
        get_in_touch: 'Get in touch!',
        info_mail: 'info@OmniFlix.network',
        name: 'Name',
        email: 'Email',
        want_ask_something: 'Let us know what you want to talk about?',
        send: 'Send',
        enter_your_email: 'Enter your email',
        subscribe: 'Subscribe',
        subscribe_text1: 'Sign up to get to stay updated about OmniFlix',
        subscribe_text2: 'subscribe for the latest news from OmniFlix',
        omniflix_network: 'OmniFlix.Network',
        world_text1: 'Made with',
        world_text2: 'from around the world!',
        marketplace: 'Marketplace',
        coming_soon: 'Coming soon',
        cosmoverse: 'Cosmoverse',
        validator_on: 'Validator on',
    },
};

export default variables;
