import { combineReducers } from 'redux';
import language from './language';
import navBar from './navBar';
import contact from './contact';
import snackbar from './snackbar';

export default combineReducers({
    navBar,
    language,
    contact,
    snackbar,
});
