import { combineReducers } from 'redux';
import {
    AUTO_HIDE_SET,
    COMMENT_SET,
    EMAIL_SET,
    NAME_SET,
    SUBMIT_CONTACT_DETAILS_ERROR,
    SUBMIT_CONTACT_DETAILS_IN_PROGRESS,
    SUBMIT_CONTACT_DETAILS_SUCCESS,
    SUBMIT_SUBSCRIBE_EMAIL_ERROR,
    SUBMIT_SUBSCRIBE_EMAIL_IN_PROGRESS,
    SUBMIT_SUBSCRIBE_EMAIL_SUCCESS,
    SUBSCRIBE_MAIL_SET,
} from '../constants/contact';

const name = (state = {
    value: '',
    valid: true,
}, action) => {
    switch (action.type) {
    case NAME_SET:
        return {
            value: action.value,
            valid: action.valid,
        };
    case SUBMIT_CONTACT_DETAILS_ERROR:
    case SUBMIT_CONTACT_DETAILS_SUCCESS:
        return {
            ...state,
            value: '',
        };
    default:
        return state;
    }
};

const email = (state = {
    value: '',
    valid: true,
}, action) => {
    switch (action.type) {
    case EMAIL_SET:
        return {
            value: action.value,
            valid: action.valid,
        };
    case SUBMIT_CONTACT_DETAILS_ERROR:
    case SUBMIT_CONTACT_DETAILS_SUCCESS:
        return {
            ...state,
            value: '',
        };
    default:
        return state;
    }
};

const comment = (state = {
    value: '',
    valid: true,
}, action) => {
    switch (action.type) {
    case COMMENT_SET:
        return {
            value: action.value,
            valid: action.valid,
        };
    case SUBMIT_CONTACT_DETAILS_ERROR:
    case SUBMIT_CONTACT_DETAILS_SUCCESS:
        return {
            ...state,
            value: '',
        };
    default:
        return state;
    }
};

const contactInProgress = (state = false, action) => {
    switch (action.type) {
    case SUBMIT_CONTACT_DETAILS_IN_PROGRESS:
        return true;
    case SUBMIT_CONTACT_DETAILS_SUCCESS:
    case SUBMIT_CONTACT_DETAILS_ERROR:
        return false;
    default:
        return state;
    }
};

const subscribeMail = (state = {
    value: '',
    valid: true,
    message: '',
    inProgress: false,
}, action) => {
    switch (action.type) {
    case SUBSCRIBE_MAIL_SET:
        return {
            ...state,
            value: action.value,
            valid: action.valid,
            message: '',
        };
    case SUBMIT_SUBSCRIBE_EMAIL_IN_PROGRESS:
        return {
            ...state,
            inProgress: true,
        };
    case SUBMIT_SUBSCRIBE_EMAIL_ERROR:
    case SUBMIT_SUBSCRIBE_EMAIL_SUCCESS:
        return {
            ...state,
            inProgress: false,
            value: '',
            message: action.message,
        };
    case AUTO_HIDE_SET:
        return {
            ...state,
            message: '',
        };
    default:
        return state;
    }
};

export default combineReducers({
    name,
    email,
    comment,
    contactInProgress,
    subscribeMail,
});
