import { ACTIVE_TAB_SET, HIDE_SIDE_BAR_SET, SHOW_SIDE_BAR_SET, WINDOW_RESIZE_SET } from '../constants/navBar';

export const showSideBar = () => {
    return {
        type: SHOW_SIDE_BAR_SET,
    };
};

export const hideSideBar = () => {
    return {
        type: HIDE_SIDE_BAR_SET,
    };
};

export const setWindowResize = (value) => {
    return {
        type: WINDOW_RESIZE_SET,
        value,
    };
};

export const setActiveTab = (value) => {
    return {
        type: ACTIVE_TAB_SET,
        value,
    };
};
