import React from 'react';
import { IconButton } from '@material-ui/core';
import Icon from '../../components/Icon';
import * as PropTypes from 'prop-types';
import variables from '../../utils/variables';

const SocialLinks = (props) => {
    const handleClose = (link) => {
        window.open(link);

        if (props.section) {
            return;
        }

        props.handleClose();
    };

    return (
        <div className="of_social_links">
            <div className="links">
                <IconButton aria-label="Twitter" onClick={() => handleClose('https://twitter.com/OmniFlixNetwork')}>
                    <Icon className="OF_twitter" icon="OF_twitter"/>
                </IconButton>
                <IconButton aria-label="Instagram" onClick={() => handleClose('https://instagram.com/OmniFlixNetwork')}>
                    <Icon className="OF_Instagram" icon="OF_Instagram"/>
                </IconButton>
                <IconButton aria-label="FaceBook" onClick={() => handleClose('https://fb.com/OmniFlixNetwork')}>
                    <Icon className="OF_facebook" icon="OF_facebook"/>
                </IconButton>
                <IconButton aria-label="Telegram" onClick={() => handleClose('https://t.me/OmniFlixNetwork')}>
                    <Icon className="OF_telegram" icon="OF_telegram"/>
                </IconButton>
                <IconButton aria-label="Discord" onClick={() => handleClose('https://discord.com/invite/6gdQ4yZSTC')}>
                    <Icon className="OF_discord" icon="OF_discord"/>
                </IconButton>
                <IconButton aria-label="Github" onClick={() => handleClose('https://github.com/OmniFlix')}>
                    <Icon className="OF_github" icon="OF_github"/>
                </IconButton>
            </div>
            <div className="tag_line">
                <span/>
                <p>{variables[props.lang]['tag_omniflix']}</p>
                <span/>
            </div>
        </div>
    );
};

SocialLinks.propTypes = {
    lang: PropTypes.string.isRequired,
    handleClose: PropTypes.func,
    section: PropTypes.bool,
};

export default SocialLinks;
