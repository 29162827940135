import { AppBar, Tab, Tabs } from '@material-ui/core';
import * as PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import variables from '../../utils/variables';
import { setActiveTab } from '../../actions/navBar';

class NavTabs extends Component {
    constructor (props) {
        super(props);

        this.handleChange = this.handleChange.bind(this);
    }

    componentDidUpdate (pp, ps, ss) {
        if (pp.show !== this.props.show && !this.props.show) {
            document.body.style.overflow = null;
        }
    }

    handleChange (e, newValue) {
        if (newValue === 'blog') {
            window.open('https://mirror.xyz/OmniFlixNetwork.eth', '_blank');

            return;
        }

        const el = document.getElementById(newValue);
        if (el && el.getBoundingClientRect() && el.getBoundingClientRect().top) {
            window.scrollTo({
                top: window.scrollY + el.getBoundingClientRect().top - this.props.size,
                behavior: 'smooth',
            });
        }

        if (this.props.show) {
            this.props.handleClose();
        }

        if (newValue === this.props.value) {
            return;
        }

        this.props.onChange(newValue);
    }

    render () {
        const a11yProps = (index) => {
            return {
                id: `simple-tab-${index}`,
                'aria-controls': `simple-tabpanel-${index}`,
            };
        };

        return (
            <AppBar className="horizontal_tabs" position="static">
                <Tabs className="tabs_content" value={this.props.value} onChange={this.handleChange}>
                    <Tab
                        disableRipple
                        className={'tab ' + (this.props.value === 'dApps' ? 'active_tab' : '')}
                        label={variables[this.props.lang].dApps}
                        value="dApps"
                        {...a11yProps(4)} />
                    <Tab
                        disableRipple
                        className={'tab ' + (this.props.value === 'ecosystem' ? 'active_tab' : '')}
                        label={variables[this.props.lang].ecosystem}
                        value="ecosystem"
                        {...a11yProps(0)} />
                    <Tab
                        disableRipple
                        className={'tab ' + (this.props.value === 'why' ? 'active_tab' : '')}
                        label={variables[this.props.lang].why}
                        value="why"
                        {...a11yProps(1)} />
                    <Tab
                        disableRipple
                        className={'tab ' + (this.props.value === 'roadmap' ? 'active_tab' : '')}
                        label={variables[this.props.lang].roadmap}
                        value="roadmap"
                        {...a11yProps(5)} />
                    <Tab
                        disableRipple
                        className={'tab ' + (this.props.value === 'infrastructure' ? 'active_tab' : '')}
                        label={variables[this.props.lang].infrastructure}
                        value="infrastructure"
                        {...a11yProps(3)} />
                    <Tab
                        disableRipple
                        className={'tab ' + (this.props.value === 'network' ? 'active_tab' : '')}
                        label={variables[this.props.lang].network}
                        value="network"
                        {...a11yProps(2)} />
                    <Tab
                        disableRipple
                        className={'tab ' + (this.props.value === 'contact' ? 'active_tab' : '')}
                        label={variables[this.props.lang].contact}
                        value="contact"
                        {...a11yProps(6)} />
                    <Tab
                        disableRipple
                        className={'tab ' + (this.props.value === 'blog' ? 'active_tab' : '')}
                        label={variables[this.props.lang].blog}
                        value="blog"
                        {...a11yProps(7)} />
                </Tabs>
            </AppBar>
        );
    }
}

NavTabs.propTypes = {
    handleClose: PropTypes.func.isRequired,
    lang: PropTypes.string.isRequired,
    show: PropTypes.bool.isRequired,
    size: PropTypes.number.isRequired,
    onChange: PropTypes.func.isRequired,
    value: PropTypes.any,
};

const stateToProps = (state) => {
    return {
        lang: state.language,
        show: state.navBar.show,
        size: state.navBar.windowSize,
        value: state.navBar.activeTab,
    };
};

const actionToProps = {
    onChange: setActiveTab,
};

export default connect(stateToProps, actionToProps)(NavTabs);
