import React from 'react';
import { ReactComponent as Logo } from '../../assets/logos/logo.svg';
import './index.css';

const CircularProgress = () => {
    return (
        <div className="circular_progress">
            <Logo/>
        </div>
    );
};

export default CircularProgress;
